import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useSwipeable } from "react-swipeable";
import { useRouter } from "next/router";

import { useMediaQueries } from "@/hooks/useMediaQueries";
import { track } from "@/helpers/analytics";

type Props = {
	videos: any[];
	autoplay: boolean;
};

export const useSocialVideo = ({ videos, autoplay }: Props) => {
	const [currentIndex, setCurrentIndex] = useState(0);
	const [indicesToShow, setIndicesToShow] = useState<number[]>([]);

	const [fullScreenIndex, setFullScreenIndex] = useState(0);
	const [fullScreenIndicesToShow, setFullScreenIndicesToShow] = useState<
		number[]
	>([]);

	const fullScreenWrapperRef = useRef<HTMLDivElement>(null);
	const cardRef = useRef<HTMLDivElement>(null);
	const animationInProgress = useRef<boolean>(false);

	const [isInFullScreenMode, setIsInFullScreenMode] = useState(false);
	const { isMobile } = useMediaQueries();
	const router = useRouter();

	const canScrollVideos = useMemo(() => {
		return (isMobile && videos.length > 2) || videos.length > 4;
	}, [isMobile, videos.length]);

	const getVideoAtIndex = useCallback(
		(indexToUse: number, increment: number): number => {
			if (videos[indexToUse + increment]) {
				return indexToUse + increment;
			}

			const newIndex = indexToUse + increment - (videos.length - 1) - 1;

			return newIndex;
		},
		[videos]
	);

	const setVideosToShow = useCallback(
		(indexToUse: number, fullScreen = isInFullScreenMode) => {
			if (fullScreen) {
				setFullScreenIndex(indexToUse);
			} else {
				setCurrentIndex(indexToUse);
			}

			// The easiest way to visualise how it's showing & loading the videos is like this:
			// 1 [ 2 3 4 5 ] 6
			// Between the square brackets is visible. 1 & 6 aren't, but they're loaded so that when the user scrolls across,
			// there's no delay in the loading.
			// This also applies at full screen & mobile, but with less numbers.

			if (fullScreen) {
				setFullScreenIndicesToShow([
					indexToUse === 0 ? videos.length - 1 : indexToUse - 1,
					indexToUse,
					getVideoAtIndex(indexToUse, 1),
				]);
				return;
			}

			const windowSize = [...new Array(isMobile ? 4 : 6)];

			setIndicesToShow(
				windowSize.map((_val, index) => getVideoAtIndex(indexToUse, index))
			);

			return;
		},
		[getVideoAtIndex, isInFullScreenMode, isMobile, videos.length]
	);

	// init
	useEffect(() => {
		if (!canScrollVideos) {
			const windowSize = [...new Array(videos.length)];

			setIndicesToShow(
				windowSize.map((_val, index) => getVideoAtIndex(0, index))
			);
			return;
		}

		if (router.isReady) {
			// set the index to the last video, so that the first video that is visible to the user is the 1st in the list.
			setCurrentIndex(videos.length - 1);
			setVideosToShow(videos.length - 1);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [router.isReady]);

	const slideFullScreenVideo = useCallback(
		(direction: "up" | "down") => {
			const wrapper = fullScreenWrapperRef.current;

			animationInProgress.current = true;

			if (wrapper) {
				wrapper.style.transition = "transform 0.5s ease-in-out";
				wrapper.style.transform =
					direction === "down" ? "translateY(-200vh)" : "translateY(0vh)";
			}

			setTimeout(() => {
				if (wrapper) {
					wrapper.style.transition = "unset";
					wrapper.style.transform = "translateY(-100vh)";
				}

				if (direction === "down") {
					setVideosToShow(
						fullScreenIndex === videos.length - 1 ? 0 : fullScreenIndex + 1
					);
					animationInProgress.current = false;
					return;
				}

				setVideosToShow(
					fullScreenIndex === 0 ? videos.length - 1 : fullScreenIndex - 1
				);
				animationInProgress.current = false;
			}, 500);
		},
		[fullScreenIndex, setVideosToShow, videos.length]
	);

	const slideCarousel = useCallback(
		(direction: "left" | "right") => {
			const cards = Array.from(
				document.querySelectorAll("[data-video-card-index]")
			);

			const card = cards[0] as HTMLDivElement;

			if (card) {
				animationInProgress.current = true;
				card.style.transition = "margin-left 0.5s ease-in-out";
				card.style.marginLeft =
					direction === "right"
						? isMobile
							? "calc((100% + 20px) * -1)"
							: "calc((50% + 4px) * -1)"
						: "0";

				setTimeout(() => {
					cards.forEach((otherCard) => {
						const style = (otherCard as HTMLDivElement).style;
						style.transition = "";
						style.marginLeft = "";
					});

					if (direction === "right") {
						setVideosToShow(
							currentIndex === videos.length - 1 ? 0 : currentIndex + 1
						);
						animationInProgress.current = false;
						return;
					}

					setVideosToShow(
						currentIndex === 0 ? videos.length - 1 : currentIndex - 1
					);
					animationInProgress.current = false;
				}, 500);
			}
		},
		[currentIndex, isMobile, setVideosToShow, videos.length]
	);

	const loadNextVideo = useCallback(() => {
		if (animationInProgress.current) {
			return;
		}

		if (isInFullScreenMode) {
			slideFullScreenVideo("down");
			track("Social Video - swipe");
			return;
		}

		if (!canScrollVideos) {
			return;
		}

		slideCarousel("right");
		track("Social Video - scroll");
	}, [
		canScrollVideos,
		isInFullScreenMode,
		slideCarousel,
		slideFullScreenVideo,
	]);

	const loadPreviousVideo = useCallback(() => {
		if (animationInProgress.current) {
			return;
		}

		if (isInFullScreenMode) {
			slideFullScreenVideo("up");
			track("Social Video - swipe");
			return;
		}

		if (!canScrollVideos) {
			return;
		}

		slideCarousel("left");
		track("Social Video - scroll");
	}, [
		canScrollVideos,
		isInFullScreenMode,
		slideCarousel,
		slideFullScreenVideo,
	]);

	const handlers = useSwipeable({
		onSwipedLeft: () => loadNextVideo(),
		onSwipedRight: () => loadPreviousVideo(),
		preventDefaultTouchmoveEvent: true,
		trackMouse: true,
	});

	const onClickVideo = useCallback(
		(index: number) => {
			setIsInFullScreenMode(true);
			setVideosToShow(index, true);
			track("Social Video - click");
		},
		[setVideosToShow]
	);

	const onCloseFullScreen = useCallback(() => {
		setIsInFullScreenMode(false);
	}, []);

	const videosToShow = useMemo(() => {
		return indicesToShow.map((number, index) => {
			const video = videos[number];
			const { videoUrl, title, id } = video;

			const uniqueId = indicesToShow.indexOf(number) !== index ? `${id}_2` : id;

			return {
				videoUrl,
				title,
				key: uniqueId,
				index: number,
				onClick: onClickVideo,
				disableScroll: !canScrollVideos,

				// Don't play videos that aren't visible, also will stop the loop behaviour and should help performance
				paused:
					isInFullScreenMode ||
					!autoplay ||
					((index === 0 || index === indicesToShow.length - 1) &&
						canScrollVideos),
			};
		});
	}, [
		autoplay,
		canScrollVideos,
		indicesToShow,
		isInFullScreenMode,
		onClickVideo,
		videos,
	]);

	return {
		loadNextVideo,
		handlers,
		loadPreviousVideo,
		onCloseFullScreen,
		isInFullScreenMode,
		fullScreenIndicesToShow,
		fullScreenIndex,
		fullScreenWrapperRef,
		cardRef,
		canScrollVideos,
		videosToShow,
	};
};
