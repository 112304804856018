import { useEffect, useMemo, useState } from "react";

import c from "classnames";
import slugify from "react-slugify";

import { ProductsSection } from "@/types/page";
import { SplitHeading } from "@/components/FormElements/SplitHeading";
import { initializeApollo } from "@/API/apolloClient";
import { getAllRecipesQuery } from "@/API/queries";
import { Button } from "@/components/FormElements/Button";
import { ButtonVariants } from "@/types/inputFields";
import { RecipeBox } from "@/components/RecipeBox/RecipeBox";

import Styles from "./products.module.scss";

type Props = {
	content: ProductsSection;
};

const GENERAL_RECIPES = "general";
const SENSITIVE_RECIPES = "sensitive";
const RENAL_RECIPES = "renal";

const recipes = {
	[GENERAL_RECIPES]: [],
	[SENSITIVE_RECIPES]: [],
	[RENAL_RECIPES]: [],
};

export const Products = ({ content }: Props) => {
	const [allRecipeData, setAllRecipeData] = useState(null);
	const [tab, setActiveTab] = useState(GENERAL_RECIPES);

	useEffect(() => {
		const getData = async () => {
			const apolloClient = initializeApollo();
			const { data } = await apolloClient.query({
				query: getAllRecipesQuery,
				context: { clientName: "dato" },
			});
			setAllRecipeData(data.allRecipes);
		};
		getData();
	}, []);

	const recipesToShow = useMemo(() => {
		return (
			content.products
				?.filter(({ handle }) => {
					if (tab === RENAL_RECIPES) {
						return handle.includes("renal");
					}

					if (tab === SENSITIVE_RECIPES) {
						return handle.includes("sensitive");
					}

					return !handle.includes("renal") && !handle.includes("sensitive");
				})
				.map((product) => product.handle) || []
		);
	}, [content.products, tab]);

	return (
		<section
			id={content.id}
			className={c("sectionSpacing", Styles.products, {
				[Styles["border-" + slugify(content.border)]]: content.border,
			})}
		>
			<div className={c("container", "text-center")}>
				{content?.heading?.[0]?.headingLine1 && (
					<>
						{content.heading[0].split ? (
							<SplitHeading
								heirarchy={content.heading[0].sizing}
								line1={content.heading[0].headingLine1}
								line2={content.heading[0].headingLine2}
								colour={content.heading[0].secondaryLineColour}
							/>
						) : (
							<h2 className="no-bottom">{content.heading[0].headingLine1}</h2>
						)}
					</>
				)}
				{content?.content && (
					<span
						className={c(
							{ paraLarge: content.heading?.[0]?.headingSize !== "small" },
							{ paraMed: content.heading?.[0]?.headingSize === "small" },
							Styles.contentText
						)}
						dangerouslySetInnerHTML={{ __html: content.content }}
					/>
				)}
			</div>
			<div className={Styles.tabs}>
				{Object.keys(recipes)?.map((type) => (
					<Button
						key={type}
						variant={
							tab === type ? ButtonVariants.default : ButtonVariants.clearLight
						}
						onClick={() => setActiveTab(type)}
						width="relative"
					>
						{type}
					</Button>
				))}
			</div>
			<div className="container">
				<div className={c(Styles.recipeWrapper)}>
					{allRecipeData &&
						recipesToShow.map((recipe) => (
							<div key={recipe}>
								<RecipeBox
									recipe={recipe}
									allRecipeData={allRecipeData}
									type="funnel"
									large={false}
									selectedRecipes={[]}
									position={{}}
									border={false}
									link={true}
								/>
							</div>
						))}
				</div>
			</div>
		</section>
	);
};
