import c from "classnames";
import slugify from "react-slugify";

import { Icon } from "@/components/FormElements/Icon";
import { SocialVideosSection } from "@/types/page";

import Styles from "./socialVideos.module.scss";
import { Card } from "./Card";
import { useSocialVideo } from "./useSocialVideo";
import { FullScreen } from "./FullScreen";

export type VideoItem = {
	title: string;
	videoUrl: string;
	snippetToPlay: string;
};

type Props = SocialVideosSection;

export const SocialVideos = ({
	subtitle,
	title,
	videos,
	backgroundColour,
	autoplay,
	showVideoLength,
}: Props) => {
	const {
		videosToShow,
		loadNextVideo,
		handlers,
		loadPreviousVideo,
		onCloseFullScreen,
		isInFullScreenMode,
		fullScreenIndicesToShow,
		fullScreenWrapperRef,
		canScrollVideos,
	} = useSocialVideo({
		videos,
		autoplay,
	});

	return (
		<div
			className={c({
				[Styles["background-" + slugify(backgroundColour)]]: backgroundColour,
			})}
		>
			<div className={c("container sectionSpacing")}>
				<div>
					<h2 className="text-center center-self narrow-bottom">{title}</h2>
					<p className="text-center center-self no-bottom">{subtitle}</p>
				</div>
				<div className={Styles.videoContainer}>
					{canScrollVideos && (
						<div className={Styles.leftIcon} onClick={loadPreviousVideo}>
							<Icon icon="CircleArrowLeft"></Icon>
						</div>
					)}
					<div className={Styles.cards} {...handlers}>
						{videosToShow.map((video) => (
							<Card
								{...video}
								key={video.key}
								snippetToPlay={"0"}
								showVideoLength={showVideoLength}
								light={
									backgroundColour === "deep green" ||
									backgroundColour === "purple"
								}
							/>
						))}
					</div>
					{canScrollVideos && (
						<div className={Styles.rightIcon}>
							<Icon icon="CircleArrowRight" onClick={loadNextVideo}></Icon>
						</div>
					)}
				</div>
				{isInFullScreenMode && (
					<FullScreen
						onNextClick={loadNextVideo}
						onPreviousClick={loadPreviousVideo}
						videos={videos}
						onClose={onCloseFullScreen}
						isOpen={true}
						indicesToShow={fullScreenIndicesToShow}
						ref={fullScreenWrapperRef}
					/>
				)}
			</div>
		</div>
	);
};
