// TODO type this file
// @ts-nocheck
import slugify from "slugify";

export function makeOrgSchema() {
	return {
		"@context": "https://schema.org",
		"@type": "Organization",
		name: "Pure Pet Food",
		url: "https://www.purepetfood.com",
		logo: "https://www.purepetfood.com/images/logo.png",
		aggregateRating: {
			"@type": "AggregateRating",
			ratingValue: "4.7",
			reviewCount: "3785",
		},
		contactPoint: [
			{
				"@type": "ContactPoint",
				telephone: "0113 868 4737",
				contactType: "customer service",
				areaServed: "GB",
				availableLanguage: "en",
			},
		],
		sameAs: [
			"https://www.facebook.com/Purepetfood",
			"https://twitter.com/PurePetFood",
			"https://www.instagram.com/pure_pet_food/",
			"https://www.youtube.com/channel/UCxob-CD1MxF_8Pc5R1oxZqw",
			"https://www.pinterest.co.uk/purepetfood/",
		],
	};
}

export function makeProductSchema(product: {
	title: string;
	images: string;
	description: string;
}) {
	const schema = {
		"@context": "https://schema.org/",
		"@type": "Product",
		name: product.title,
		image: "/images/uploads" + product.images,
		description: product.description,
		brand: "Pure Pet Food",
		sku: slugify(product.title),
	};

	return schema;
}

export function makeHealthInfoPageSchema(page) {
	const schema = {
		"@context": "https://schema.org",
		"@type": "Article",
		mainEntityOfPage: {
			"@type": "WebPage",
			"@id": "https://www.purepetfood.com" + page.slug,
		},
		image: "/images/uploads/fb_k3csvw.jpg",
		headline: page.title,
		description: page.metadata?.[0]?.description,
		publisher: {
			"@type": "Organization",
			name: "Pure Pet Food",
			logo: {
				"@type": "ImageObject",
				url: "https://www.purepetfood.com/images/logo.png",
				width: 141,
				height: 59,
			},
		},
	};

	return schema;
}

export function makeHelpSchema(help, url, authors) {
	const schema = {
		"@context": "https://schema.org",
		"@type": "Article",
		mainEntityOfPage: {
			"@type": "WebPage",
			"@id": "https://www.purepetfood.com" + url,
		},
		image: "/images/uploads/fb_k3csvw.jpg",
		headline: help.title,
		description: help.metadata[0].description,
		publisher: {
			"@type": "Organization",
			name: "Pure Pet Food",
			logo: {
				"@type": "ImageObject",
				url: "https://www.purepetfood.com/images/logo.png",
				width: 141,
				height: 59,
			},
		},
	};

	if (authors && authors.length > 0) {
		schema.author = authors.map((author) => {
			return {
				name: author.name,
				description: author.description,
				"@type": "Person",
			};
		});
	}

	return schema;
}

export function makeFAQPageSchema(content, url, faqSchema) {
	const schema = {
		"@context": "https://schema.org",
		"@type": content.title === "FAQ" ? "FAQPage" : "Article",
		mainEntityOfPage: {
			"@type": "WebPage",
			"@id": "https://www.purepetfood.com" + url,
		},
		image: "/images/uploads/fb_k3csvw.jpg",
		headline: content.title,
		description: content.description,
		publisher: {
			"@type": "Organization",
			name: "Pure Pet Food",
			logo: {
				"@type": "ImageObject",
				url: "https://www.purepetfood.com/images/logo.png",
				width: 141,
				height: 59,
			},
		},
	};

	if (faqSchema && faqSchema.length > 0) {
		schema.mainEntity = faqSchema;
	}
	return schema;
}

export function makeBlogSchema(blog, url, authors) {
	const schema = {
		"@context": "https://schema.org",
		"@type": "BlogPosting",
		mainEntityOfPage: {
			"@type": "WebPage",
			"@id": "https://www.purepetfood.com" + url,
		},
		headline: blog.title,
		description: blog.metadata[0].description,
		image: blog.coverImage[0].image.url,

		publisher: {
			"@type": "Organization",
			name: "Pure Pet Food",
			logo: {
				"@type": "ImageObject",
				url: "https://www.purepetfood.com/images/logo.png",
				width: 141,
				height: 59,
			},
		},
		datePublished: blog.metadata[0].date,
		dateModified: blog.metadata[0].date,
	};

	if (authors && authors.length > 0) {
		schema.author = authors.map((author) => {
			return {
				name: author.name,
				description: author.description,
				"@type": "Person",
			};
		});
	}

	return schema;
}

export function makeAboutPageSchema(content, url) {
	const schema = {
		"@context": "https://schema.org",
		"@type": "AboutPage",
		author: "Pure Pet Food",
		about: "Our story - Pure Pet Food",
		headline: "Our passion, our story",
		description: content.description,
		image: "/images/uploads/fb_k3csvw.jpg",
		publisher: {
			"@type": "Organization",
			name: "Pure Pet Food",
			logo: {
				"@type": "ImageObject",
				url: "https://www.purepetfood.com/images/logo.png",
				width: 141,
				height: 59,
			},
		},
		mainEntityOfPage: {
			"@type": "WebPage",
			"@id": "https://www.purepetfood.com/" + url,
		},
	};

	return schema;
}
