import {
	MouseEventHandler,
	useCallback,
	useEffect,
	useRef,
	useState,
} from "react";

import { useSwipeable } from "react-swipeable";

type Props = {
	onNextClick: () => void;
	onPreviousClick: () => void;
	isOpen: boolean;
	indicesToShow: number[];
};

export const useFullScreen = ({
	onNextClick,
	onPreviousClick,
	isOpen,
	indicesToShow,
}: Props) => {
	const [isMuted, setIsMuted] = useState(false);
	const videoRef = useRef<HTMLVideoElement>(null);
	const interval = useRef<ReturnType<typeof setInterval> | null>(null);
	const [currentProgress, setCurrentProgress] = useState(0);
	const [playing, setIsPlaying] = useState(false);
	const autoPlayFromStartNumber = useRef<number | null>(null);

	const onVolumePress = useCallback(() => setIsMuted(!isMuted), [isMuted]);

	const handlers = useSwipeable({
		onSwipedUp: onNextClick,
		onSwipedDown: onPreviousClick,
		preventDefaultTouchmoveEvent: true,
		trackMouse: true,
	});

	useEffect(() => {
		const onPlay = () => setIsPlaying(true);
		const onPause = () => setIsPlaying(false);

		const video = videoRef.current;

		if (video === null) {
			return;
		}

		video.addEventListener("play", onPlay);
		video.addEventListener("pause", onPause);
		return () => {
			video.removeEventListener("play", onPlay);
			video.removeEventListener("pause", onPause);
		};
	}, [setIsPlaying]);

	useEffect(() => {
		if (!videoRef?.current) {
			return;
		}

		interval.current = setInterval(() => {
			if (videoRef.current === null) {
				return;
			}
			setCurrentProgress(
				Math.round(
					(videoRef.current.currentTime / videoRef.current.duration) * 100
				)
			);
		}, 500);

		return () => {
			interval.current && clearInterval(interval.current);
		};
	}, [playing]);

	const videoClicked = useCallback(() => {
		if (!videoRef.current) {
			return;
		}

		if (!videoRef.current.paused) {
			setIsPlaying(false);
			videoRef.current?.pause();
			return;
		}

		setIsPlaying(true);
		videoRef.current?.play();
	}, []);

	const progressMax = 100;

	const preventEventBubble: MouseEventHandler<HTMLDivElement> = useCallback(
		(event) => {
			event.stopPropagation();
			event.preventDefault();
		},
		[]
	);

	useEffect(() => {
		if (
			indicesToShow[1] !== autoPlayFromStartNumber.current &&
			videoRef.current
		) {
			videoRef.current.currentTime = 0;
			videoRef.current?.play().then(() => {
				autoPlayFromStartNumber.current = indicesToShow[1];
			});
		}
	}, [indicesToShow]);

	return {
		onVolumePress,
		isMuted,
		handlers,
		progressMax,
		currentProgress,
		videoRef,
		videoClicked,
		preventEventBubble,
	};
};
