import { useCallback, useEffect, useRef, useState } from "react";

const INTERVAL_OF_LOOP = 8000;

type Props = {
	paused: boolean;
	snippetToPlay: string;
	title: string;
};

export const useCard = ({ paused, snippetToPlay, title }: Props) => {
	const videoRef = useRef<HTMLVideoElement | null>(null);
	const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);
	const [videoLength, setVideoLength] = useState("00:00");

	const loopVideo = useCallback(() => {
		if (!videoRef.current) {
			return;
		}

		const start = parseInt(snippetToPlay);

		try {
			videoRef.current.pause();
			videoRef.current.currentTime = start;
			videoRef.current.play();
		} catch (e) {
			console.log(e);
		}
	}, [snippetToPlay]);

	useEffect(() => {
		if (!videoRef.current) {
			return;
		}

		if (paused) {
			videoRef.current.pause();
			intervalRef.current && clearInterval(intervalRef.current);
			return;
		}

		try {
			videoRef.current.play();
		} catch (e) {
			console.log(e);
		}

		// Memory leak protection
		if (intervalRef.current) {
			clearInterval(intervalRef.current);
		}

		intervalRef.current = setInterval(loopVideo, INTERVAL_OF_LOOP);
	}, [loopVideo, paused]);

	useEffect(() => {
		if (!videoRef.current) {
			return;
		}

		const video = videoRef.current as NonNullable<HTMLVideoElement>;

		const onLoadedMetaData = (event: any) => {
			const duration = event.srcElement.duration;

			if (duration && !isNaN(duration)) {
				setVideoLength(
					`${(duration / 100).toFixed(2).replace(".", ":") || "00:00"}`
				);
			}
		};

		video.addEventListener("loadedmetadata", onLoadedMetaData);

		// wait for video to start playing before we start the loop
		const onPlay = () => {
			if (intervalRef.current) {
				return;
			}

			intervalRef.current = setInterval(loopVideo, INTERVAL_OF_LOOP);
		};

		// if the interval isn't there, it is fairly safe to assume the listener isn't either.
		// This was an issue in development as it wouldn't re-attach the event listener after a live reload
		// I didn't want this to also be an issue if the useEffect clean up runs in production
		if (!intervalRef.current) {
			// Video is already playing, so the play event won't run
			if (!video.paused) {
				onPlay();
			}
			video.addEventListener("play", onPlay);
		}

		// Clean up
		return () => {
			video.removeEventListener("play", onPlay);
			intervalRef.current && clearInterval(intervalRef.current);
			intervalRef.current = null;
			video.removeEventListener("loadedmetadata", onLoadedMetaData);
		};
	}, [loopVideo, snippetToPlay, title]);

	return { videoRef, videoLength };
};
