import c from "classnames";
import slugify from "react-slugify";
import { Image, ResponsiveImageType } from "react-datocms";

import { TableSectionProps } from "@/types/page";
import { SplitHeading } from "@/components/FormElements/SplitHeading";
import { Button } from "@/components/FormElements/Button";
import { ButtonVariants } from "@/types/inputFields";
import { useMediaQueries } from "@/hooks/useMediaQueries";

import Styles from "./table.module.scss";
import { TableCell } from "./TableCell";

type Props = {
	content: TableSectionProps;
	alignToSides?: boolean;
	tableLogo?: ResponsiveImageType;
	cta?: {
		action: "modal" | "link";
		text: string;
		link: string;
	};
	onShowModal?: () => void;
	hideContent?: boolean;
	span?: number;
};

export const Table = ({
	content,
	alignToSides = false,
	tableLogo,
	cta,
	onShowModal,
	hideContent = false,
	span = 8,
}: Props) => {
	const { isMobile, isDesktop } = useMediaQueries();

	if (hideContent) {
		return <></>;
	}

	const halfColsEmpty = span ? 12 - span : null;
	const start = halfColsEmpty ? halfColsEmpty / 2 + 1 : null;
	const colSpan =
		span && start
			? {
					gridColumn: isMobile
						? `1 / span 6`
						: !isDesktop
							? `2 / span 10`
							: `${start} / span ${span}`,
				}
			: {};

	return (
		<section
			id={content.id}
			className={c("sectionSpacing", {
				[Styles.purpleBackground]: content.backgroundColour === "purple",
				[Styles.deepGreenBackground]: content.backgroundColour === "deep green",
				[Styles.greenBackground]: content.backgroundColour === "green",
				[Styles.bottomZigZag]: content.bottomBorderZigZag,
				[Styles["sideIcons-" + slugify(content.sideIcons)]]: content.sideIcons,
			})}
		>
			<div className={c("container", "largeMd", "containerNoPad")}>
				<div style={colSpan} className={Styles.tableWrapper}>
					<div className={Styles.tableLogo}>
						{tableLogo && (
							<Image
								data={{
									...tableLogo,
									width: isMobile ? 64 : 96,
									height: isMobile ? 64 : 96,
								}}
							/>
						)}
					</div>
					{!content.tableHeading2 && (
						<h2 className={Styles.heading}>{content.tableHeading}</h2>
					)}
					{content.tableHeading2 && (
						<SplitHeading
							className={Styles.heading}
							colour={
								content.backgroundColour === "green"
									? "dark"
									: content.backgroundColour === "deep green"
										? "green"
										: content.backgroundColour === "purple"
											? "yellow"
											: "orange"
							}
							line1={content.tableHeading}
							line2={content.tableHeading2}
						/>
					)}
					<div className={Styles.tableContainer}>
						<div
							className={c(Styles.table, {
								[Styles.alignToSides]: alignToSides,
							})}
						>
							{content.tableRows.map(({ rowContent, rowIsAHeading }, index) => {
								return (
									<div
										className={c(Styles.tableRow, {
											[Styles.tableHeading]: rowIsAHeading,
											[Styles.splitCells]: rowContent.length === 2,
										})}
										key={index}
									>
										{rowContent.map((props, i) => (
											<TableCell {...props} key={i} />
										))}
									</div>
								);
							})}
						</div>
					</div>
					{cta && (
						<div className={c("text-center", Styles.cta)}>
							<Button
								variant={ButtonVariants.clearLight}
								width="relative"
								{...(cta.action === "link"
									? { href: cta.link }
									: {
											onClick: onShowModal,
										})}
							>
								{cta.text}
							</Button>
						</div>
					)}
				</div>
			</div>
		</section>
	);
};
