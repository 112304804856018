import { LegacyRef, forwardRef } from "react";

import c from "classnames";

import { Icon } from "@/components/FormElements/Icon";
import { SocialVideosSection } from "@/types/page";

import Styles from "./fullScreen.module.scss";
import { useFullScreen } from "./useFullScreen";

type Props = {
	videos: SocialVideosSection["videos"];
	onNextClick: () => void;
	onPreviousClick: () => void;
	onClose: () => void;
	isOpen: boolean;
	indicesToShow: number[];
};

export const FullScreen = forwardRef(
	(
		{
			videos,
			onNextClick,
			onPreviousClick,
			onClose,
			isOpen,
			indicesToShow,
		}: Props,
		ref: LegacyRef<HTMLDivElement> | undefined
	) => {
		const {
			isMuted,
			onVolumePress,
			handlers,
			progressMax,
			currentProgress,
			videoRef,
			videoClicked,
			preventEventBubble,
		} = useFullScreen({
			onNextClick,
			onPreviousClick,
			isOpen,
			indicesToShow,
		});

		if (!isOpen) {
			return <></>;
		}

		return (
			<div className={Styles.wrapper} {...handlers}>
				<div
					className={c(Styles.control, Styles.close)}
					onClick={(e) => {
						preventEventBubble(e);
						onClose();
					}}
				>
					<Icon icon="Close" height="16px" width="16px" />
				</div>
				{/* <div
					className={c(Styles.control, Styles.share)}
					onClick={(e) => {
						preventEventBubble(e);
					}}
				>
					<Icon icon="Share" height="16px" width="16px" />
				</div> */}
				<div
					className={c(Styles.control, Styles.volume)}
					onClick={(e) => {
						preventEventBubble(e);
						onVolumePress();
					}}
				>
					<Icon icon={isMuted ? "Mute" : "Volume"} height="16px" width="16px" />
				</div>

				<div className={Styles.directionControls} onClick={preventEventBubble}>
					<div className={Styles.control} onClick={onPreviousClick}>
						<Icon icon="FullScreenChevronUp" height="16px" width="16px" />
					</div>
					<div className={Styles.control} onClick={onNextClick}>
						<Icon icon="FullScreenChevronDown" height="16px" width="16px" />
					</div>
				</div>
				<div className={Styles.videoWrapper} ref={ref}>
					{indicesToShow.map((number) => (
						<div
							className={Styles.video}
							key={videos[number].title}
							onClick={videoClicked}
						>
							<div className={Styles.progressContainer}>
								<video
									autoPlay={false}
									loop={true}
									muted={indicesToShow[1] !== number || isMuted}
									ref={indicesToShow[1] === number ? videoRef : undefined}
									controls={false}
									playsInline={true}
								>
									<source src={`${videos[number].videoUrl}`} type="video/mp4" />
								</video>
								<progress
									value={indicesToShow[1] !== number ? 0 : currentProgress}
									max={progressMax}
									className={Styles.progress}
								/>
							</div>
						</div>
					))}
				</div>
			</div>
		);
	}
);

FullScreen.displayName = "FullScreen";
