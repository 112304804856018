import c from "classnames";

import { Icon } from "@/components/FormElements/Icon";

import Styles from "./card.module.scss";
import { useCard } from "./useCard";

type Props = {
	title: string;
	onClick: (index: number) => void;
	snippetToPlay: string;
	videoUrl: string;
	index: number;
	paused: boolean;
	showVideoLength: boolean;
	light: boolean;
	disableScroll: boolean;
};

export const Card = ({
	onClick,
	snippetToPlay,
	title,
	videoUrl,
	index,
	paused,
	showVideoLength,
	light,
	disableScroll,
}: Props) => {
	const { videoRef, videoLength } = useCard({ paused, snippetToPlay, title });

	return (
		<div
			className={c(Styles.card, { [Styles.disableScroll]: disableScroll })}
			onClick={() => onClick(index)}
			data-video-card-index={index}
		>
			<Icon
				icon="PlayBtn"
				className={Styles.playButton}
				height="48px"
				width="48px"
				noFill
			/>
			<div className={Styles.video}>
				<video
					ref={videoRef}
					autoPlay={false}
					loop={true}
					muted={true}
					controls={false}
					playsInline={true}
				>
					<source src={`${videoUrl}`} type="video/mp4" />
				</video>
				{showVideoLength && (
					<div className={Styles.videoLength}>
						{videoLength !== "00:00" && videoLength}
					</div>
				)}
			</div>
			<p className={c("caption bold", { bright: light })}>{title}</p>
		</div>
	);
};
