import c from "classnames";

import { TableSectionProps } from "@/types/page";
import { Icon } from "@/components/FormElements/Icon";
import { useMediaQueries } from "@/hooks/useMediaQueries";
import { useUserDataStore } from "@/store/UserDataStore";

import Styles from "./table.module.scss";

export const TableCell = ({
	content,
	contentColour,
	contentIsDeleted,
	largeContent,
	largeContentColour,
	image,
	isATickCell,
	tick,
	cellIsAHeading,
	isABlankCell,
	largeText,
	alternateTick,
	cellShowsUserDiscount,
}: TableSectionProps["tableRows"][0]["rowContent"][0]) => {
	const [user] = useUserDataStore((state) => [state.user]);
	const classNames = c(Styles.tableCell, {
		[Styles.tableHeading]: cellIsAHeading,
		[Styles.largeText]: largeText,
		[Styles.white]: contentColour === "White",
		[Styles.yellow]: contentColour === "Yellow",
		[Styles.whiteLarge]: largeContentColour === "White",
		[Styles.yellowLarge]: largeContentColour === "Yellow",
	});

	const { isTabletLarge, isTablet } = useMediaQueries();

	if (cellShowsUserDiscount) {
		return (
			<div className={c(classNames)}>
				<div className={Styles.largeContent}>
					{user.voucher &&
						`${
							user.voucher?.type === "fixed"
								? "£" + (user.voucher.amount / 100).toFixed(0)
								: user.voucher?.amount + "%"
						}
				off today`}
				</div>
			</div>
		);
	}

	if (isABlankCell) {
		return <div className={classNames}></div>;
	}

	if (isATickCell) {
		const size = isTabletLarge ? "48px" : isTablet ? "36px" : "32px";

		return tick ? (
			<div className={c(classNames, { [Styles.altTick]: alternateTick })}>
				<div>
					<Icon
						icon={alternateTick ? "IconTick" : "PureCheckCircle"}
						noFill
						height={size}
						width={size}
					/>
				</div>
			</div>
		) : (
			<div className={classNames}>
				<div>
					<Icon icon="PureCrossCircle" noFill height={size} width={size} />
				</div>
			</div>
		);
	}

	if (image) {
		return (
			<div className={classNames}>
				<img src={image.url} alt={content} />
			</div>
		);
	}

	if (contentIsDeleted) {
		return (
			<div className={classNames}>
				<del>{content}</del>
				{largeContent && (
					<div className={Styles.largeContent}>{largeContent}</div>
				)}
			</div>
		);
	}

	return (
		<div className={classNames}>
			{content}
			{largeContent && (
				<div className={Styles.largeContent}>{largeContent}</div>
			)}
		</div>
	);
};
